
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

import DialogLayout from './DialogLayout.vue';

@Component({
  components: { DialogLayout }
})
export default class extends Vue {
  /* Properties */
  @Prop() valueToChange!: string;
  @Prop() currentValue!: string;

  /* Store Actions */

  /* Watchers */

  /* Data */
  dialog: boolean = false;
  newValue: string = '';

  save() {
    this.$emit('save', this.newValue);
    this.closeDialog();
  }

  openDialog() {
    this.newValue = this.currentValue;
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Computed */

  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
