
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import SimpleFormDialog from '@/components/SimpleFormDialog.vue';
import AuthService from '@/services/auth-service';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
@Component({
  components: { DefaultLayout, SimpleFormDialog }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    SimpleFormDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  firstName: string = '';
  lastName: string = '';
  userName: string = '';

  valueToChange: string = '';
  currentValue: string = '';
  type: number = 0;

  newPassword: string = '';
  confirmPassword: string = '';
  changePasswordDialog: boolean = false;
  isPasswordFormValid: boolean = false;
  shouldShowPassword: boolean = false;
  /* Utility Functions */
  async GetUserInfo() {
    const { data } = await AuthService.GetUserInfo();

    this.firstName = data.FirstName;
    this.lastName = data.LastName;
    this.userName = data.UserName;
  }
  openSimpleFormDialog(type: number) {
    this.type = type;
    switch (type) {
      case 0:
        this.valueToChange = 'First Name';
        this.currentValue = this.firstName;
        break;
      case 1:
        this.valueToChange = 'Last Name';
        this.currentValue = this.lastName;
        break;
    }
    setTimeout(() => {
      this.$refs.SimpleFormDialog.openDialog();
    }, 100);
  }
  openChangePasswordDialog() {
    this.newPassword = '';
    this.confirmPassword = '';
    this.changePasswordDialog = true;
  }
  canChangePassword() {
    return this.newPassword.length >= 8 && this.newPassword == this.confirmPassword;
  }
  async changePassword() {
    const formData: any = new FormData();
    formData.set('Username', this.userName);
    formData.set('Password', this.newPassword);
    try {
      await AuthService.changePassword(formData);
      SetSnackBar('Password changed successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error changing your password. Please contact techsupport@just1label.com'
      );
    }
    this.changePasswordDialog = false;
  }
  saveElement(value: string) {
    switch (this.type) {
      case 0:
        this.setFirstName(value);
        return;
      case 1:
        this.setLastName(value);
        return;
    }
  }
  async setFirstName(firstName: string) {
    try {
      await AuthService.SetFirstName(firstName);
      this.GetUserInfo();
      SetSnackBar('Changed first name successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error setting your first name. Please contact techsupport@just1label.com'
      );
    }
  }
  async setLastName(lastName: string) {
    try {
      await AuthService.SetLastName(lastName);
      this.GetUserInfo();
      SetSnackBar('Changed last name successfully');
    } catch (err) {
      SetSnackBar(
        'There was an error setting your last name. Please contact techsupport@just1label.com'
      );
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.GetUserInfo();
  }
  /* Created */
  /* Emmited Functions */
}
